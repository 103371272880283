import React from 'react';
import PropTypes from 'prop-types';
import Header from './header';
import ViewItem from './viewItem';

const MeasurementView = ({ data = null }) => (
  <div className="vf-measurement-view">
    <Header data={data} />
    {data.map((measurement, i) => (
      <ViewItem data={measurement} index={i} key={`measurement-${measurement.id}`} />
    ))}
  </div>
);

MeasurementView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default MeasurementView;
