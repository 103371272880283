import React from 'react';
import PropTypes from 'prop-types';
import Component from 'components/component';
import Color from 'utils/color';
import Formatter from 'utils/formatter';
import Router from 'utils/router';
import ReduxActions from 'reduxActions';
import { Utils, translate } from 'versafleet-core';
import TranslationHelper from 'utils/translationHelper';
import TaskModule from 'modules/task';
import JobCustomAddress from './customAddress';
import JobCustomDateTime from './customDateTime';
import { withRouterHooks } from '../../utils/router';

const { Helper } = Utils;

class JobEditForm extends Component.Form {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      isEditForm: true,
      isUpdating: false,
      error: {},
    };
    this.basePath = this.props.basePath
      || ((Router.getPath().indexOf('jobs') !== -1) ? 'jobs' : 'tasks');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this._updateForm(this.props);
    }
  }

  _closeForm = () => {
    this.props.navigate('..');
  }

  _updateForm = (props) => {
    this.setState({ form: this._getDefaultForm(props.data) });
  }

  _onFormChange(fieldName, value, shouldChange = true) {
    let form;
    if (fieldName.indexOf('customer') !== -1) {
      if (value !== '' && typeof value !== 'object' && fieldName.indexOf('id') !== -1) {
        this.props.dispatch(ReduxActions.customer.fetchBillingAccounts(value));
      }

      let customerId;

      if (typeof value === 'object') {
        customerId = undefined;
      } else if (value === '') {
        customerId = null;
      } else {
        customerId = value;
      }

      form = {
        ...this.state.form,
        customer_id: customerId,
        customer_attributes: typeof value !== 'object' ? undefined : {
          name: value.text,
        },
        base_task_attributes: {
          ...this.state.form.base_task_attributes,
          billing_account_id: null,
          billing_account_attributes: undefined,
        },
      };
      this.setState({ form });
    } else if (fieldName.indexOf('billing_account_id') !== -1) {
      let billingAccountId;

      if (typeof value === 'object') {
        billingAccountId = undefined;
      } else if (value === '') {
        billingAccountId = null;
      } else {
        billingAccountId = value;
        // Fetch billing accounts for the locations
        this.props.dispatch(ReduxActions.billingAccount.fetch(value));
      }

      form = {
        ...this.state.form,
        base_task_attributes: {
          ...this.state.form.base_task_attributes,
          billing_account_id: billingAccountId,
          billing_account_attributes: typeof value !== 'object' ? undefined : {
            name: value.text,
            customer_id: this.state.form.customer_id,
          },
        },
      };
      this.setState({ form });
    } else if (fieldName === 'job_type' && value !== 'p2p') {
      form = {
        ...this.state.form,
        job_type: value,
        base_task_attributes: {
          ...this.state.form.base_task_attributes,
          billing_account_id: null,
          billing_account_attributes: undefined,
        },
      };
      this.setState({ form });
    } else if (fieldName.indexOf('attachments_attributes') !== -1) {
      form = {
        ...this.state.form,
        attachments_attributes: value,
      };
      this.setState({ form });
    } else {
      // eslint-disable-next-line no-underscore-dangle
      super._onFormChange(fieldName, value, shouldChange);
      form = this.state.form;
    }
    if (this.props.onFormChange) {
      this.props.onFormChange(fieldName, value, form);
    }

    this.setState(prevState => ({ isChanged: shouldChange ? true : prevState.isChanged }));
  }

  // eslint-disable-next-line class-methods-use-this
  _isPointToPoint(data) {
    return data.job_type === 'p2p' || (data.job_type === 'delivery' && data.tags && data.tags.filter(tag => tag.name === 'Point-to-Point').length > 0);
  }

  _getDefaultForm(data) {
    const newData = data || {};
    const baseTask = newData.base_task_attributes || newData.base_task || {};

    return {
      customer_id: Helper.isNullOrUndefined(newData.customer) ? newData.customer_id
        : newData.customer.id,
      customer_attributes: newData.customer_attributes,
      job_type: this._isPointToPoint(newData) ? 'p2p' : (newData.job_type || 'delivery'),
      base_task_attributes: {
        billing_account_id: baseTask.billing_account_id
          || (baseTask.billing_account && baseTask.billing_account.id),
        billing_account_attributes: newData.billing_account_attributes,
        address_attributes:
          (baseTask.address_attributes && this._getDefaultAddress(baseTask.address_attributes))
          || this._getDefaultAddress(baseTask.address),
        time_type: baseTask.time_type,
        time_from: baseTask.time_from,
        time_to: baseTask.time_to,
        time_window_id: Helper.isNullOrUndefined(baseTask.time_window)
          ? baseTask.time_window_id : baseTask.time_window.id,
        service_time: this._getServiceTimeDefaultForm(baseTask),
      },
      tag_list: newData.tag_list
        || (Helper.isNullOrUndefined(newData.tags) ? [] : newData.tags.map(tag => tag.name))
          .filter(tag => tag !== 'Point-to-Point'),
      remarks: newData.remarks,
      attachments_attributes: this._getAttachmentsDefaultForm(newData),
      tasks_attributes: newData.tasks_attributes || [],
    };
  }

  _getAttributes() {
    const { form } = this.state;
    return {
      ...form,
      base_task_attributes: {
        ...form.base_task_attributes,
        service_time: Number.isNaN(Number(form.base_task_attributes.service_time)) ? undefined
          : Number(form.base_task_attributes.service_time) * 60,
      },
    };
  }

  _getServiceTimeDefaultForm(data) {
    const { account, basePath } = this.props;
    const addOns = account.addons_setting || {};
    if (!addOns.vroom || !addOns.scheduler || basePath !== 'jobs'
    || Helper.isNullOrUndefined(data.service_time)) {
      return undefined;
    } if (this._isNewJob()) {
      return data.service_time;
    }

    return Helper.roundToOneDecimalPlace(data.service_time / 60);
  }

  // eslint-disable-next-line class-methods-use-this
  _getAttachmentsDefaultForm(data) {
    const attachments = data.attachments_attributes || data.attachments || [];
    return attachments.map(attachment => ({
      id: attachment.id,
      name: attachment.name,
      url: attachment.url,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  _getDefaultAddress(data) {
    const newData = data || {};
    return {
      name: newData.name,
      line_1: newData.line_1,
      line_2: newData.line_2,
      city: newData.city,
      zip: newData.zip,
      country: newData.country,
      latitude: newData.latitude,
      longitude: newData.longitude,
      contact_person: newData.contact_person,
      contact_number: newData.contact_number,
      country_code: newData.country_code,
      email: newData.email,
    };
  }

  _getDefaultAddressList(data) {
    return data.map(address => this._getDefaultAddress(address));
  }

  _getAddresses() {
    const selectedBillingAccountId = this.state.form.base_task_attributes.billing_account_id;
    const billingAccount = this.props.billingAccounts[selectedBillingAccountId];
    if (billingAccount && billingAccount.addresses && billingAccount.addresses.length > 0) {
      return billingAccount.addresses.concat(
        this._getDefaultAddressList(this.props.optionAddresses),
      );
    }
    return this._getDefaultAddressList(this.props.optionAddresses);
  }

  _getCustomerOptions() {
    return this.props.optionCustomers.map(option => ({
      value: option.id,
      text: option.name,
    }));
  }

  _getAccountOptions() {
    const { customer } = this.props;
    if (customer && !Helper.isNullOrUndefined(customer.billing_accounts)) {
      return customer.billing_accounts.map(account => ({
        value: account.id,
        text: account.name,
      }));
    }
    return [];
  }

  // eslint-disable-next-line consistent-return
  _getAccountName(id) {
    for (let i = 0; i < this.props.data.customer.billing_accounts.length; i += 1) {
      if (id === this.props.data.customer.billing_accounts[i].id) {
        return this.props.data.customer.billing_accounts[i].name;
      }
    }
  }

  // eslint-disable-next-line consistent-return
  _findCustomer(id) {
    for (let i = 0; i < this.props.optionCustomers.length; i += 1) {
      if (this.props.optionCustomers[i].id === id) {
        return this.props.optionCustomers[i];
      }
    }
  }

  _addATask = (task) => {
    const job = {
      ...this.state.form,
      customer: !Helper.isNullOrUndefined(this.state.form.customer_id)
        ? this._findCustomer(this.state.form.customer_id)
        : this.state.form.customer_attributes,
    };

    if (!Helper.isNullOrUndefined(this.props.onAddATask)) {
      this.props.onAddATask(task, job);
    }
  }

  _editTaskDetail(i) {
    if (!Helper.isNullOrUndefined(this.props.onEditTask)) {
      this.props.onEditTask(i);
    }
  }

  _removeTask(index) {
    if (!Helper.isNullOrUndefined(this.props.onRemoveTask)) {
      this.props.onRemoveTask(index);
    }
  }

  _isNewJob() {
    return Helper.isNullOrUndefined(this.props.data)
      || Helper.isNullOrUndefined(this.props.data.id);
  }

  _allowAddTask() {
    return !((!Helper.isNullOrUndefined(this.props.optionCustomers)
      && Helper.isNullOrUndefined(this.state.form.customer_id)
      && Helper.isNullOrUndefined(this.state.form.customer_attributes))
      || !this._isNewJob()
      || (this.state.form.job_type === 'p2p' && this.state.form.tasks_attributes.length > 0));
  }

  _save = () => {
    let form = this._getAttributes();

    if (form.job_type === 'p2p') {
      form = {
        ...form,
        job_type: 'delivery',
        tag_list: form.tag_list.concat(['Point-to-Point']),
      };
    }

    this.setState({ isUpdating: true }, async () => {
      try {
        if (this._isNewJob()) {
          await this.props.dispatch(ReduxActions.job.create(form));
          this.setState({ isUpdating: false });
          this.props.navigate('..');
        } else {
          const result = await this.props.dispatch(
            ReduxActions.job.update(this.props.data.id, form),
          );
          Router.transitionFromSubpath('jobs', result.id);
        }
      } catch (error) {
        this.setState({ isUpdating: false, error });
      }
    });
  }

  _renderMenu() {
    if (this.props.data != null && this.props.data.guid) {
      return (
        <Component.Form.Title>
          <Component.Form.Title.Item isHighlighted>
            {`Editing Job #${this.props.data.guid}`}
          </Component.Form.Title.Item>
        </Component.Form.Title>
      );
    }

    return (
      <Component.Form.Title>
        <Component.Form.Title.Item isHighlighted>
          {this.props.strings.form.menu.createNewJob}
        </Component.Form.Title.Item>
        <Component.Form.Title.Item>
          {this.props.strings.form.menu.createNewTask}
        </Component.Form.Title.Item>
      </Component.Form.Title>
    );
  }

  /* eslint-disable max-len */
  _getJobTypeOptions() {
    const strings = this.props.strings.constants;
    const jobTypeStrings = this.props.strings.form.jobType;

    const replaceMap = {
      one: strings.one,
      many: strings.many,
    };

    return [
      {
        value: 'delivery',
        text: (
          <Component.Input.RadioButton.Item
            description={(
              <div>
                {TranslationHelper.replaceTag(jobTypeStrings.deliveryDescription, replaceMap)}
              </div>
            )}
            name={Formatter.capitalizeFirstLetter(strings.delivery)}
            onClick={() => this._onFormChange('job_type', 'delivery')}
          />
        ),
      }, {
        value: 'pickup',
        text: (
          <Component.Input.RadioButton.Item
            description={(
              <div>
                {TranslationHelper.replaceTag(jobTypeStrings.pickupDescription, replaceMap)}
              </div>
            )}
            name={Formatter.capitalizeFirstLetter(strings.pickup)}
            onClick={() => this._onFormChange('job_type', 'pickup')}
          />
        ),
      }, {
        value: 'p2p',
        text: (
          <Component.Input.RadioButton.Item
            description={(
              <div>
                {TranslationHelper.replaceTag(jobTypeStrings.p2pDescription, replaceMap)}
              </div>
            )}
            name={strings.pointToPoint}
            onClick={() => this._onFormChange('job_type', 'p2p')}
          />
        ),
      },
    ];
  }
  /* eslint-enable */

  _renderHeader() {
    if (Helper.isNullOrUndefined(this.props.optionCustomers)) {
      return false;
    }
    const error = this.state.error.base_task || {};
    const value = this.state.form.customer_id
      || (this.state.form.customer_attributes || {}).name;

    return (
      <Component.Form.MainHeader
        iconClassName="versa-business"
        iconText=""
        title={(
          <Component.Input.Select
            allowCustom
            autoFocus
            editable={this.state.form.tasks_attributes.length === 0}
            error={error.customer}
            id="vf-job__edit-form__left-panel__main-info__select-customer-input"
            onChange={v => this._onFormChange('customer_id', v)}
            options={this._getCustomerOptions()}
            placeholder={this.props.strings.form.customerName.hint}
            value={value}
          />
        )}
      />
    );
  }

  _renderJobType() {
    const error = this.state.error || [];
    return (
      <div>
        <Component.Form.Block level={1}>
          {this.props.strings.form.jobType.title}
        </Component.Form.Block>
        <Component.Form.Block fontWeight="REGULAR" level={1}>
          <Component.Input.RadioButton
            id="vf-job__edit-form__left-panel__main-info__job-type-input"
            name="job-type"
            onChange={value => this._onFormChange('job_type', value)}
            options={this._getJobTypeOptions()}
            value={this.state.form.job_type}
          />
          <Component.Error
            errors={error.job_type}
          />
        </Component.Form.Block>
      </div>
    );
  }

  _renderPickUpInfo() {
    const { strings } = this.props;
    const error = this.state.error.base_task || {};
    return (
      <div>
        <Component.Form.Block level={1}>
          {this.state.form.job_type === 'pickup' ? strings.form.jobType.deliveryInfo
            : strings.form.jobType.pickupInfo}
        </Component.Form.Block>

        {
          this.state.form.job_type === 'p2p'
            ? (
              <div>
                <Component.Form.Block level={2}>
                  <Component.Input.Select
                    allowCustom
                    error={error.billing_account}
                    id="vf-job__edit-form__left-panel__main-info__select-account-input"
                    onChange={v => this._onFormChange('base_task_attributes.billing_account_id', v)}
                    options={this._getAccountOptions()}
                    placeholder={strings.form.pickupInfo.accountHint}
                    value={this.state.form.base_task_attributes.billing_account_id
                      || (this.state.form.base_task_attributes.billing_account_attributes
                        || {}).name}
                  />
                </Component.Form.Block>
                <Component.Form.Divider size="SMALL" />
              </div>
            ) : false
        }

        <JobCustomAddress
          addressList={this._getAddresses()}
          error={error.address}
          iconClassName={
            this.state.form.job_type === 'pickup'
              ? 'versa-arrow-down' : 'versa-arrow-up'
          }
          id="vf-job__edit-form__left-panel__main-info__address"
          onChange={(value, shouldChange) => this._onFormChange(
            'base_task_attributes.address_attributes', value, shouldChange,
          )}
          value={this.state.form.base_task_attributes.address_attributes}
        />

        <Component.Form.Divider size="SMALL" />

        <JobCustomDateTime
          error={error}
          id="vf-job__edit-form__left-panel__main-info"
          onChange={value => this._onFormChange('base_task_attributes', value)}
          value={this.state.form.base_task_attributes}
        />
      </div>
    );
  }

  _renderServiceTimeInput() {
    const { account, basePath, strings } = this.props;
    const { error } = this.state;
    const addOns = account.addons_setting || {};

    if (!addOns.vroom || !addOns.scheduler || basePath !== 'jobs') {
      return false;
    }

    return (
      <Component.Form.Block fontColor="GREY" level={2}>
        <Component.Form.Divider size="SMALL" />
        {`${strings.form.additionalInfo.serviceTime.title}:`}
        <Component.Input.Textbox
          className="vf-job__edit-form__service-time"
          error={error.service_time || []}
          id="vf-job__edit-form__left-panel__additional-info__service-time-input"
          onChange={value => this._onFormChange('base_task_attributes.service_time', value)}
          showEllipsis={false}
          value={this.state.form.base_task_attributes.service_time}
        />
        {strings.form.additionalInfo.serviceTime.mins}
      </Component.Form.Block>
    );
  }

  _renderAdditionalInfo() {
    const { strings } = this.props;
    const error = this.state.error.base_task || {};

    return (
      <Component.Form.Dropdown
        id="vf-job__edit-form__left-panel__additional-info"
        parent={(
          <Component.Form.Block level={1}>{strings.form.additionalInfo.title}</Component.Form.Block>
        )}
        showByDefault
      >
        <Component.Form.Block iconClassName="versa-person" level={2}>
          <Component.Input.Textbox
            error={error.contact_person}
            id="vf-job__edit-form__left-panel__additional-info__contact-person-input"
            onChange={value => this._onFormChange(
              'base_task_attributes.address_attributes.contact_person', value,
            )}
            placeholder={strings.form.additionalInfo.contactPersonHint}
            value={this.state.form.base_task_attributes
              .address_attributes.contact_person}
          />
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block iconClassName="versa-call" level={2}>
          <div className="vf-job__edit-form__country-code-input">
            <Component.Input.Textbox
              id="vf-job__edit-form__left-panel__additional-info__country-code-input"
              leadingLabel={Helper.isNonEmptyString(this.state.form.base_task_attributes.address_attributes.country_code) ? '+' : null}
              onChange={value => this._onFormChange(
                'base_task_attributes.address_attributes.country_code', value,
              )}
              placeholder={strings.form.additionalInfo.countryCodeHint}
              value={this.state.form.base_task_attributes
                .address_attributes.country_code}
              width="slim"
            />
            <Component.Input.Textbox
              id="vf-job__edit-form__left-panel__additional-info__contact-number-input"
              onChange={value => this._onFormChange(
                'base_task_attributes.address_attributes.contact_number', value,
              )}
              placeholder={strings.form.additionalInfo.contactNumberHint}
              value={this.state.form.base_task_attributes
                .address_attributes.contact_number}
            />
          </div>
          <Component.Error
            errors={(error?.address?.country_code || [])
              .concat(error?.address?.contact_number || [])}
          />
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block iconClassName="versa-email" level={2}>
          <Component.Input.Textbox
            error={error.address && (error.address.email || [])}
            id="vf-job__edit-form__left-panel__additional-info__contact-email-input"
            onChange={value => this._onFormChange(
              'base_task_attributes.address_attributes.email', value,
            )}
            placeholder={strings.form.additionalInfo.contactEmailHint}
            value={this.state.form.base_task_attributes
              .address_attributes.email}
          />
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block
          className="vf-job__edit-form__multi-select"
          iconClassName="versa-tags"
          iconContainerClassName="vf-job__edit-form__multi-select__icon"
          level={2}
        >
          <Component.Input.Tag
            error={error.tag_list}
            id="vf-job__edit-form__left-panel__additional-info__tag-input"
            onChange={value => this._onFormChange('tag_list', value)}
            options={this.props.tags.map(tag => ({ value: tag, text: tag }))}
            placeholder={strings.form.additionalInfo.tagHint}
            value={this.state.form.tag_list}
          />
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block iconClassName="versa-note" level={2}>
          <Component.Input.Textarea
            error={error.remarks}
            id="vf-job__edit-form__left-panel__additional-info__remarks-input"
            onChange={value => this._onFormChange('remarks', value)}
            placeholder={strings.form.additionalInfo.remarksHint}
            value={this.state.form.remarks}
            width={Component.Input.Width.WIDE}
          />
        </Component.Form.Block>
        <Component.Form.Divider size="SMALL" />
        <Component.Form.Block level={2}>
          <Component.InputAttachment
            id="vf-job__edit-form__left-panel__additional-info__attachment-input"
            onChange={value => this._onFormChange('attachments_attributes', value)}
            removable
            value={this.state.form.attachments_attributes}
          />
          <Component.Error
            errors={Array.isArray(this.state.error.attachments)
              ? (this.state.error.attachments.find(
                attachment => !Helper.isNullOrUndefined(attachment.name),
              ) || {}).name : ''}
          />
        </Component.Form.Block>
        {this._renderServiceTimeInput()}
      </Component.Form.Dropdown>
    );
  }

  _renderCustomInfo() {
    const strings = this.props.strings.form.customInfo;
    return (
      <div>
        <Component.Form.Block level={1}>{strings.title}</Component.Form.Block>
        <Component.Form.Block level={2}>
          {strings.description}
        </Component.Form.Block>
      </div>
    );
  }

  _renderTask(task, index) {
    return (
      <TaskModule.GroupItem
        account={this.props.account}
        id="vf-job__edit-form__left-panel__task-info__item"
        index={index + 1}
        job={this.props.data}
        key={`task-groupitem-${index}`}
        onDelete={this._isNewJob() ? () => this._removeTask(index) : null}
        onEdit={this._isNewJob() ? () => this._editTaskDetail(index) : null}
        optionCustomers={this.props.optionCustomers}
        task={task}
      />
    );
  }

  _renderTaskSection() {
    const { strings } = this.props;

    const tasks = this._isNewJob()
      ? this.state.form.tasks_attributes
      : this.props.data.tasks;
    const taskList = (tasks || []).map((task, i) => this._renderTask(task, i));
    const icon = (Helper.isNullOrUndefined(this.state.form.job_type)
    || this.state.form.job_type === 'pickup'
      ? 'versa-arrow-up'
      : 'versa-arrow-down');

    const taskType = (Helper.isNullOrUndefined(this.state.form.job_type)
      ? 'delivery' : this.state.form.job_type);
    const taskCount = `${taskList.length} ${(taskType === 'pickup' ? strings.constants.pickupPlural
      : strings.constants.deliveryPlural)}`;

    return (
      <div>
        <Component.Form.MultipleGroupHeader fontWeight="BOLD" level={0}>
          {Formatter.capitalizeFirstLetter(strings.constants.taskPlural)}
          {(this._allowAddTask()) ? (
            <Component.Form.MultipleGroupButton
              hintText={null}
              iconClassName="versa-add"
              id="vf-job__edit-form__left-panel__task-info__add-task-button"
              onClick={this._addATask}
              text={strings.form.buttons.addTask}
            />
          ) : false}
        </Component.Form.MultipleGroupHeader>

        {
          (tasks || []).length > 0
            ? (
              <div>
                <Component.Form.Block contentClassName="vf-job__edit-form__task-count" fontWeight="REGULAR" level={1}>
                  <Component.CircleIcon
                    className="vf-job__edit-form__task-count__circle-icon"
                    iconClassName={icon}
                    key={this.state.form.job_type || 'delivery'}
                    outerIconColor={Color.$bluelighter3}
                  />
                  {taskCount}
                </Component.Form.Block>
                {taskList}
              </div>
            )
            : (
              <Component.Form.Block className="" fontWeight="REGULAR" level={1}>
                {strings.form.task.noTask}
              </Component.Form.Block>
            )
        }
      </div>
    );
  }

  _renderLeftPanel() {
    return (
      <Component.Form.LeftPanel className="vf-job__edit-form">
        {this._renderHeader()}
        <Component.Form.Divider size="MEDIUM" />
        {this._renderJobType()}
        <Component.Form.Divider />
        {this._renderPickUpInfo()}
        <Component.Form.Divider size="LARGE" />
        {this._renderAdditionalInfo()}
        <Component.Form.Divider size="MEDIUM" />
        {/* this._renderCustomInfo() */}
        {this._renderTaskSection()}
      </Component.Form.LeftPanel>
    );
  }

  _renderButton() {
    const strings = this.props.strings.form.buttons;
    return (
      <div>
        <Component.Button.Done
          disabled={this._isNewJob() && this.state.form.tasks_attributes.length === 0}
          id="vf-job__edit-form__right-panel__save-button"
          loading={this.state.isUpdating}
          onClick={this._save}
        >
          {this.state.isUpdating ? strings.saving : strings.save}
        </Component.Button.Done>
        {(!this._allowAddTask()) ? false : (
          <Component.Button.Add
            hint={null}
            id="vf-job__edit-form__right-panel__add-task-button"
            onClick={this._addATask}
          >
            {strings.addTask}
          </Component.Button.Add>
        )}
      </div>
    );
  }
}

JobEditForm.displayName = 'Job Edit Form';

/*
  The props onSave and onAddATask are functions that are meant to be
  called by this component's parent so that the parent can achieve
  any intended behaviour, such as swapping to another form
*/
JobEditForm.propTypes = {
  account: PropTypes.shape({}),
  billingAccounts: PropTypes.arrayOf(PropTypes.shape()),
  customer: PropTypes.shape({}),
  data: PropTypes.shape({}),
  dispatch: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  onAddATask: PropTypes.func,
  onEditTask: PropTypes.func,
  onFormChange: PropTypes.func,
  onRemoveTask: PropTypes.func,
  onSave: PropTypes.func,
  optionAddresses: PropTypes.arrayOf(PropTypes.shape()),
  optionCustomers: PropTypes.arrayOf(PropTypes.shape()),
  strings: PropTypes.shape({}),
  tags: PropTypes.arrayOf(PropTypes.string),
};

JobEditForm.defaultProps = {
  billingAccounts: [],
  optionAddresses: [],
  tags: [],
};

export default withRouterHooks(translate('Job')(JobEditForm));
