import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Utils, translate } from 'versafleet-core';
import ReduxActions from 'reduxActions';
import Component from 'components/component';
import addOnsSettings from 'pages/addOns/addOnsConstants';
import featuresSettings from 'pages/featuresConstants';
import Color from 'utils/color';
import Router from 'utils/router';
import NewDialog from 'utils/newDialog';
import AccessRightHelper from 'utils/accessRightHelper';
import AssignButton from './assign/assignButton';
import AllocateButton from './allocate/allocateButton';
import DeleteButton from './archive/deleteButton';
import CompleteDialog from './complete/completeDialog';
import CancelDialog from './cancel/cancelDialog';
import LabelConstants from './labelConstants';

const { Helper, TaskMergeTags } = Utils;

class TaskListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDuplicatingSuccess: false,
      isDuplicating: false,
      duplicatedTaskId: undefined,
      disableButton: { print: false, printTaskLabel: false },
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.data !== this.props.data) {
      return true;
    }
    if (nextProps.checkedStatus !== this.props.checkedStatus) {
      return true;
    }
    if (nextProps.isActionButtonLoading !== this.props.isActionButtonLoading) {
      // When renderActionButtons/renderPersistentActionButtons are passed in as props, the buttons
      // do not get re-rendered unless task data has updated; isActionButtonLoading prop is used
      // here to check for changes in order to trigger a re-rendering and to show the loading state
      return true;
    }
    if (this.state.isDuplicating !== nextState.isDuplicating
        || this.state.isDuplicatingSuccess !== nextState.isDuplicatingSuccess) {
      return true;
    }
    if (Helper.isDifferent(
      this.state.disableButton,
      nextState.disableButton,
    )) {
      return true;
    }
    return false;
  }

  _archive = async () => {
    await this.props.dispatch(ReduxActions.task.archive(parseInt(this.props.data.id, 10)));
  }

  _unarchive = async () => {
    await this.props.dispatch(ReduxActions.task.unarchive(this.props.data.id));
    this.props.onSuccessfulUpdate();
  }

  _print = () => {
    this.props.dispatch(ReduxActions.task.print(this.props.data.id));
    this._disableButton('print');
  }

  _printTaskLabel = () => {
    this.props.dispatch(ReduxActions.task.printTaskLabel(this.props.data.id));
    this._disableButton('printTaskLabel');
  }

  _updateVersaDrive = () => {
    this.props.dispatch(ReduxActions.task.submit(this.props.data.id));
  }

  _restart = () => {
    const strings = this.props.strings.Task.form.buttons;
    NewDialog.DialogManager.confirm(
      strings.restart.confirmation,
      async () => {
        await this.props.dispatch(ReduxActions.task.restart(this.props.data.id));
        if (!Helper.isNullOrUndefined(this.props.taskFilter)) {
          this.props.dispatch(ReduxActions.task.fetchList(this.props.taskFilter));
        }
      },
    );
  }

  _cancel = () => {
    NewDialog.DialogManager.open(
      <CancelDialog
        dataIds={[this.props.data.id]}
        taskFilter={this.props.taskFilter}
      />,
    );
  }

  _duplicate = () => {
    const strings = this.props.strings.Task.form.buttons;
    NewDialog.DialogManager.confirm(
      strings.duplicateConfirm.confirmation,
      async () => {
        this.setState({
          isDuplicating: true,
        });
        const returnedValue = await this.props.dispatch(
          ReduxActions.task.duplicate(this.props.data.id),
        );
        this.setState({
          duplicatedTaskId: returnedValue.task.id,
          isDuplicatingSuccess: true,
          isDuplicating: false,
        });

        this.props.onSuccessfulUpdate();
        this._showDuplicateSuccessMessage();
        setTimeout(() => {
          this.setState({
            isDuplicatingSuccess: false,
          });
        }, 2000);
      },
    );
  }

  _showDuplicateSuccessMessage() {
    Utils.SuccessMessage.SuccessMessageManager.flash((
      <div className="vf-task__duplicate-success-message">
        <div className="vf-task__duplicate-success-message__icon">
          <i className="versa-completed" />
        </div>
        <div>
          {
            this.props.strings.Task.page.duplicateSuccess
          }
        </div>
        <div
          className="vf-task__duplicate-success-message__duplicate-go-to-task"
          onClick={() => {
            Router.transitionTo(`/planning/tasks/${this.state.duplicatedTaskId}`);
          }}
        >
          {
            this.props.strings.Task.page.duplicateGoToTask
          }
        </div>
      </div>
    ), 6000);
  }

  _isPointToPoint = data => data.job && data.job.job_type === 'delivery' && data.job.tags && data.job.tags.filter(tag => tag.name === 'Point-to-Point').length > 0

  _disableButton(buttonName) {
    this.setState(prevState => ({
      disableButton: { ...prevState.disableButton, [buttonName]: true },
    }), async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.setState(prevState => ({
        disableButton: { ...prevState.disableButton, [buttonName]: false },
      }));
    });
  }

  _onComplete = () => {
    NewDialog.DialogManager.open(
      <CompleteDialog
        dataIds={[this.props.data.id]}
        taskFilter={this.props.taskFilter}
      />,
    );
  }

  _onUnallocate = async () => {
    const { data, onSuccessfulUpdate } = this.props;
    await this.props.dispatch(ReduxActions.task.unallocate(data.id));
    onSuccessfulUpdate();
  };

  _renderCol1() {
    const { job } = this.props.data;
    const isPickup = job && job.job_type === 'pickup';

    const iconClassName = classNames({
      'versa-pickup': job && isPickup,
      'versa-delivery': job && !isPickup,
    });
    return (
      <Component.LineItem.IconBlock
        iconClassName={iconClassName}
        iconText=""
      />
    );
  }

  _renderCol2() {
    const {
      data, account, customTaskLayout, tags,
    } = this.props;
    if (Helper.isNullOrUndefined(account)) {
      return false;
    }

    const items = [];
    for (let i = 1; i < customTaskLayout.col_2.length; i += 1) {
      if (!Helper.isNullOrUndefined(customTaskLayout.col_2[i])) {
        const itemData = Utils.TaskMergeTags.translateKeyword(customTaskLayout.col_2[i], data, tags,
          null, null, account);
        if (!Helper.isNullOrUndefined(itemData)) {
          items.push(
            <Component.LineItem.BlockContent key={`col-2-${i + 1}`}>
              {itemData}
            </Component.LineItem.BlockContent>,
          );
        }
      }
    }

    return (
      <Component.LineItem.Block size="MEDIUM">
        <Component.LineItem.BlockContent fontColor="BLACK" fontSize="LARGE" key="col-2-1">
          {Utils.TaskMergeTags.translateKeyword(customTaskLayout.col_2[0], data, tags,
            null, null, account)}
        </Component.LineItem.BlockContent>
        {items}
      </Component.LineItem.Block>
    );
  }

  _renderCol3() {
    const {
      data, account, customTaskLayout, tags,
    } = this.props;
    if (Helper.isNullOrUndefined(account)) {
      return false;
    }

    const items = [];
    for (let i = 0; i < customTaskLayout.col_3.length; i += 1) {
      if (!Helper.isNullOrUndefined(customTaskLayout.col_3[i])) {
        const itemData = TaskMergeTags.translateKeyword(customTaskLayout.col_3[i], data, tags,
          null, null, account);
        if (!Helper.isNullOrUndefined(itemData)) {
          items.push(
            <Component.LineItem.BlockContent key={`col-3-${i}`}>
              {itemData}
            </Component.LineItem.BlockContent>,
          );
        }
      }
    }
    return (
      <Component.LineItem.Block>
        {items}
      </Component.LineItem.Block>
    );
  }

  _renderCol4() {
    const {
      data, account, customTaskLayout, tags,
    } = this.props;
    if (Helper.isNullOrUndefined(account)) {
      return false;
    }

    const items = [];
    for (let i = 0; i < customTaskLayout.col_4.length; i += 1) {
      if (!Helper.isNullOrUndefined(customTaskLayout.col_4[i])) {
        const itemData = TaskMergeTags.translateKeyword(customTaskLayout.col_4[i], data, tags,
          null, null, account);
        if (!Helper.isNullOrUndefined(itemData)) {
          items.push(
            <Component.LineItem.BlockContent key={`col-4-${i}`}>
              {itemData}
            </Component.LineItem.BlockContent>,
          );
        }
      }
    }

    return (
      <Component.LineItem.Block>
        {items}
      </Component.LineItem.Block>
    );
  }

  _renderCol5() {
    const {
      data, account, customTaskLayout, tags,
    } = this.props;
    if (Helper.isNullOrUndefined(account)) {
      return false;
    }

    const items = [];
    for (let i = 0; i < customTaskLayout.col_5.length; i += 1) {
      if (!Helper.isNullOrUndefined(customTaskLayout.col_5[i])) {
        const itemData = TaskMergeTags.translateKeyword(customTaskLayout.col_5[i], data, tags,
          null, null, account);
        if (!Helper.isNullOrUndefined(itemData)) {
          items.push(
            <Component.LineItem.BlockContent key={`col-5-${i}`}>
              {itemData}
            </Component.LineItem.BlockContent>,
          );
        }
      }
    }

    return (
      <Component.LineItem.Block>
        {items}
      </Component.LineItem.Block>
    );
  }

  _renderCol6() {
    const { data, strings } = this.props;
    const ta = this.props.data.task_assignment || {};
    const { attendant } = ta;
    const vehiclePart = ta.vehicle_part;
    const vehiclePartPlateNumber = Helper.isNullOrUndefined(vehiclePart) ? '' : ` - ${vehiclePart.plate_number}`;
    const attendantName = Helper.isNullOrUndefined(attendant) ? '' : ` - ${attendant.name}`;
    const isVersadriver = ta.driver && ta.driver.is_versadrive_user;
    const iconClassName = classNames({
      'versa-driver': ta.driver && !isVersadriver,
      'versa-vdriver': isVersadriver,
    });

    const circleIconClassName = classNames({
      'vf-task__list-item__driver__icon': !Helper.isNullOrUndefined(ta.driver),
      'vf-task__list-item__driver__icon--versadrive': isVersadriver,
    });

    return (
      <Component.LineItem.Block>
        <Component.LineItem.BlockContent
          key="col-6-1"
        >
          <Component.Label
            backgroundColor={LabelConstants.labelColors[data.state]}
            whiteText={LabelConstants.whiteTextLabel[data.state]}
          >
            {strings.Task.status[data.state]}
          </Component.Label>
          {(data.is_partial_success && data.state === 'successful') ? (
            <span className="vf-task__list-item__state__partial-flag">
              <Component.Hint
                className="vf-task__list-item__state__partial-flag__hint"
                hintPosition={Component.Hint.Position.TOP}
              >
                {strings.Task.page.partialSuccess}
              </Component.Hint>
              <i className="versa-flagged vf-task__list-item__state__partial-flag" />
            </span>
          ) : false}
          {this._renderItemDiscrepancy()}
        </Component.LineItem.BlockContent>
        {
          data.state !== 'cancelled' ? (
            <Component.LineItem.BlockContent className="vf-task__list-item__driver" key="col-6-2">
              {
                Helper.isNonEmptyString(iconClassName)
                  ? (
                    <Component.CircleIcon
                      className={circleIconClassName}
                      iconClassName={iconClassName}
                      iconText=""
                      strokeColor={isVersadriver ? Color.$gold : null}
                    />
                  ) : false
              }
              <div className="vf-task__list-item__driver__details">
                {(Helper.isNullOrUndefined(ta.driver)) ? false : (
                  <div>
                    {ta.driver.name}
                    {attendantName}
                  </div>
                )}
                {(Helper.isNullOrUndefined(ta.vehicle)) ? false : (
                  <div>
                    {ta.vehicle.plate_number}
                    {vehiclePartPlateNumber}
                  </div>
                )}
              </div>
            </Component.LineItem.BlockContent>
          ) : false
        }
      </Component.LineItem.Block>
    );
  }

  _renderCol7() {
    const { data, account, strings } = this.props;
    const isAllocated = data.account_created_id !== data.account_id;
    let assignedAccountName;

    if (isAllocated) {
      const assignedAccount = (account.direct_child_accounts || [])
        .find(item => item.id === data.account_id || data.allocated_account_ids.includes(item.id));

      assignedAccountName = (assignedAccount || {}).name;
    }

    // Display transporter column only for parent account with runsheet_manager_setting enabled
    // Allocation status is also only shown to parent account; child account will
    //   see only "accepted" tasks in task list, and only "pending" tasks in pending task list
    return account.runsheet_manager_enabled
      && Helper.isNonEmptyArray(account.direct_child_accounts)
      ? (
        <div className="vf-task__list-item__allocated_transporter__details">
          <Component.Label
            backgroundColor={LabelConstants.labelColors[data.allocation_state]}
            whiteText={LabelConstants.whiteTextLabel[data.allocation_state]}
          >
            {strings.Task.allocationStatus[data.allocation_state]}
          </Component.Label>
          {
            isAllocated && !Helper.isNullOrUndefined(assignedAccountName)
              ? assignedAccountName : strings.Task.page.noTransporter
          }
        </div>
      ) : false;
  }

  _renderItemDiscrepancy() {
    const { data } = this.props;

    return !Helper.isNullOrUndefined(data.line_item_discrepancy)
      && data.line_item_discrepancy !== 0
      && (
        <div className="vf-task__list-item__discrepancy">
          <i
            className="vf-task__list-item__discrepancy__icon versa-warning-triangle"
          />
          <span
            className="vf-task__list-item__discrepancy__text"
          >
            {data.line_item_discrepancy}
          </span>
        </div>
      );
  }

  _renderActionButtons = () => {
    const { accessRights, archived, data } = this.props;
    if (!archived) {
      return null;
    }
    const printButton = (
      <Component.Button.Print
        disabled={this.state.disableButton.print}
        id="vf-task__list-item__print-button"
        onClick={this._print}
      />
    );
    const unarchiveButton = (data.state === 'successful' && AccessRightHelper.hasAccess(accessRights, 'task.edit_after_successful'))
      || (data.state !== 'successful' && AccessRightHelper.hasAccess(accessRights, 'task.archive'))
      ? (
        <Component.Unarchive
          id="vf-task__list-item__unarchive-button"
          modelName={this.props.strings.Common.models.task.singular}
          onClick={this._unarchive}
        />
      ) : false;
    const deleteButton = (
      <DeleteButton
        accessRightKey="task.archive"
        dataId={parseInt(data.id, 10)}
        id="vf-task__list-item__delete-button"
      />
    );
    return (
      <Component.LineItem.ButtonGroup>
        {printButton}
        {unarchiveButton}
        {deleteButton}
      </Component.LineItem.ButtonGroup>
    );
  }

  _renderPersistentActionButtons() {
    const {
      archived, accessRights, account, data, index, isUpdatingVersadrive, onSuccessfulUpdate,
    } = this.props;
    const { disableButton, isDuplicating, isDuplicatingSuccess } = this.state;
    const strings = this.props.strings.Task.form.buttons;

    const ta = data.task_assignment || {};
    const assignData = {
      driver_id: ta.driver && ta.driver.id,
      attendant_id: ta.attendant && ta.attendant.id,
      vehicle_id: ta.vehicle && ta.vehicle.id,
      vehicle_part_id: ta.vehicle_part && ta.vehicle_part.id,
      estimated_start_time: ta.estimated_start_time,
      remarks: data.remarks,
    };
    const parents = account.parent_accounts || [];

    const isAssigned = !Helper.isNullOrUndefined(ta.driver) && data.state !== 'unassigned';
    const isAllocated = data.account_created_id !== data.account_id;
    const isTaskCreator = data.account_created_id === account.id;
    const isSubaccountOfOwner = parents.some(parent => parent.id === data.account_created_id);
    const accountIsInCharge = account.id === data.account_id;
    const canUnallocate = data.allocated_account_ids[data.allocated_account_ids.length - 1]
      === account.id;
    const taskDuplicated = isDuplicating || isDuplicatingSuccess;

    const canPlan = AccessRightHelper.hasAccess(accessRights, 'task.plan');
    const canEdit = AccessRightHelper.hasAccess(accessRights, 'task.edit');
    const canEditAfterSuccess = AccessRightHelper.hasAccess(accessRights, 'task.edit_after_successful');
    const canSubaccountUpdate = accountIsInCharge && isSubaccountOfOwner;
    const canAllocateWithTaskState = data.state === 'unassigned'
      || data.state === 'assigned'
      || data.state === 'acknowledged'
      || data.state === 'declined'
      || data.state === 'failed';

    const updateButton = taskDuplicated ? false : (
      <Component.Button.UpdateVersaDrive
        accessRightKey="task.plan"
        hintPosition={
          index === 0
          // eslint-disable-next-line camelcase
          && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
            ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
        }
        id="vf-task__list-item__update-versadrive-button"
        loading={isUpdatingVersadrive}
        onClick={this._updateVersaDrive}
      />
    );
    const assignButton = archived || (taskDuplicated && isAssigned)
      || (isAllocated && !canSubaccountUpdate)
      ? false : (
        <AssignButton
          accessRightKey="task.plan"
          alignRight
          data={assignData}
          hint={isAssigned ? 'Reassign' : 'Assign'}
          hintPosition={
            index === 0
            // eslint-disable-next-line camelcase
            && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
              ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
          }
          iconClassName="versa-add"
          id="vf-task__list-item__assign-button"
          onSuccessfulUpdate={onSuccessfulUpdate}
          taskId={parseInt(data.id, 10)}
        >
          <i className="versa-driver" />
        </AssignButton>
      );

    const transferButton = archived || (taskDuplicated && isAssigned)
      || (isAllocated && !canSubaccountUpdate)
      ? false : (
        // https://versafleet.atlassian.net/browse/VF-2462
        // Driver transfer is just a driver reassignment without the changing of states
        // Use the same base component for assign and transfer
        <AssignButton
          accessRightKey="task.plan"
          alignRight
          data={assignData}
          hint={strings.transfer}
          hintPosition={
            index === 0
            // eslint-disable-next-line camelcase
            && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
              ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
          }
          iconClassName="versa-swap-horiz"
          id="vf-task__list-item__transfer-button"
          isTaskTransfer
          onSuccessfulUpdate={onSuccessfulUpdate}
          taskId={parseInt(data.id, 10)}
        />
      );
    const allocateButton = account.runsheet_manager_enabled
      && Helper.isNonEmptyArray(account.direct_child_accounts)
      && canAllocateWithTaskState
      && (!isAllocated || canSubaccountUpdate)
      ? (
        <AllocateButton
          accessRightKey="task.plan"
          alignRight
          hint={strings.allocate.hint}
          hintPosition={
            index === 0
            // eslint-disable-next-line camelcase
            && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
              ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
          }
          iconClassName="versa-add"
          id="vf-task__list-item__allocate-button"
          onSuccessfulUpdate={onSuccessfulUpdate}
          taskId={parseInt(data.id, 10)}
        >
          {strings.allocate.text}
        </AllocateButton>
      ) : false;

    const unallocateButton = account.runsheet_manager_enabled
      && Helper.isNonEmptyArray(account.direct_child_accounts)
      && (data.state === 'unassigned' || data.state === 'cancelled' || data.state === 'successful')
      && canUnallocate
      && (isTaskCreator || isSubaccountOfOwner)
      ? (
        <Component.LineItem.ButtonMenu.Button.Hidden
          className="vf-task__list-item__unallocate-button"
          hint={strings.allocate.hint}
          hintPosition={
            index === 0
            // eslint-disable-next-line camelcase
            && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
              ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
          }
          iconClassName="versa-close"
          id="vf-task__list-item__unallocate-button"
          onClick={this._onUnallocate}
        >
          {strings.unallocate.text}
        </Component.LineItem.ButtonMenu.Button.Hidden>
      ) : false;
    const completeButton = (!isAllocated || canSubaccountUpdate)
      && canPlan && !isDuplicating && !isDuplicatingSuccess
      ? (
        <Component.LineItem.ButtonMenu.Button.Hidden
          iconClassName="versa-completed"
          id="vf-task__list-item__complete-button"
          onClick={this._onComplete}
        >
          {strings.complete.button}
        </Component.LineItem.ButtonMenu.Button.Hidden>
      ) : false;
    const editButton = !isDuplicating && !isDuplicatingSuccess
      && ((data.state === 'successful' && canEditAfterSuccess)
      || (data.state !== 'successful' && canEdit))
      && !isAllocated ? (
        <Component.LineItem.ButtonMenu.Button.Hidden
          iconClassName="versa-edit"
          id="vf-task__list-item__edit-button"
          onClick={() => Router.transitionFromSubpath(data.id, 'edit')}
        >
          {strings.edit}
        </Component.LineItem.ButtonMenu.Button.Hidden>
      ) : false;
    const taskLabelButton = !taskDuplicated
      && !Helper.isNullOrUndefined(account.addons_setting)
      && account.addons_setting.custom_task_label === addOnsSettings.subscribed
      ? (
        <Component.LineItem.ButtonMenu.Button.Hidden
          disabled={disableButton.printTaskLabel}
          iconClassName="versa-label"
          id="vf-task__list-item__task-label__button"
          onClick={this._printTaskLabel}
        >
          {strings.viewTaskLabel}
        </Component.LineItem.ButtonMenu.Button.Hidden>
      ) : false;
    const printButton = taskDuplicated ? false : (
      <Component.LineItem.ButtonMenu.Button.Hidden
        disabled={disableButton.print}
        iconClassName="versa-print"
        id="vf-task__list-item__print-button"
        onClick={this._print}
      >
        {strings.print}
      </Component.LineItem.ButtonMenu.Button.Hidden>
    );
    const cancelButton = canEdit && !isDuplicating && !isDuplicatingSuccess ? (
      <Component.LineItem.ButtonMenu.Button.Hidden
        iconClassName="versa-danger"
        onClick={this._cancel}
      >
        {strings.cancel}
      </Component.LineItem.ButtonMenu.Button.Hidden>
    ) : false;
    const restartButton = AccessRightHelper.hasAccess(accessRights, 'task.restart_task') && !isDuplicating && !isDuplicatingSuccess && !isAllocated ? (
      <Component.LineItem.ButtonMenu.Button.Hidden
        iconClassName="versa-restore-defaults"
        id="vf-task__list-item__restart-button"
        onClick={this._restart}
      >
        {strings.restart.button}
      </Component.LineItem.ButtonMenu.Button.Hidden>
    ) : false;
    const archiveButton = !isDuplicating && !isDuplicatingSuccess
      && ((data.state === 'successful' && canEditAfterSuccess && isTaskCreator)
      || (data.state !== 'successful' && !isAllocated && AccessRightHelper.hasAccess(accessRights, 'task.archive'))
        ? (
          <Component.Archive.Hidden
            archive={this._archive}
            archivePath={() => Router.transitionTo('archives/tasks')}
            delete={async () => {
              await this.props.dispatch(ReduxActions.task.delete(data.id));
            }}
            ids={[data.id]}
            modelName={this.props.strings.Common.models.task.singular}
            updateContent={() => { this.props.dispatch(ReduxActions.task.fetchList()); }}
          >
            <Component.LineItem.ButtonMenu.Button.Hidden
              iconClassName="versa-archive"
              loading={isDuplicating}
            >
              {strings.archive}
            </Component.LineItem.ButtonMenu.Button.Hidden>
          </Component.Archive.Hidden>
        ) : false
      );
    const duplicateButton = isTaskCreator && canEdit && !this._isPointToPoint(data) ? (
      <Component.LineItem.ButtonMenu.Button.Hidden
        color={isDuplicatingSuccess ? Component.Button.Color.GREEN : Component.Button.Color.BLUE}
        iconClassName={isDuplicatingSuccess ? 'versa-completed' : 'versa-copy'}
        loading={isDuplicating}
        onClick={this._duplicate}
      >
        {(!isDuplicating && !isDuplicatingSuccess) && strings.duplicate}
        {isDuplicating && strings.duplicating}
        {isDuplicatingSuccess && strings.duplicated}
      </Component.LineItem.ButtonMenu.Button.Hidden>
    ) : false;

    if (archived) {
      return (
        // Render empty ButtonGroup (no actions) for archived task
        // {false} is used to silent the proptype warning since children prop is required
        <Component.LineItem.ButtonGroup
          accessRightKey="task.plan"
          isPersistent
        >
          {false}
        </Component.LineItem.ButtonGroup>
      );
    }

    return (
      <Component.LineItem.ButtonMenu
        containerClassName="vf-task__list-item__more-actions-dropdown"
        hintPosition={
          index === 0
          // eslint-disable-next-line camelcase
          && this.props.account?.features_setting?.virtualized_list === featuresSettings.enabled
            ? Component.Hint.Position.BOTTOM : Component.Hint.Position.TOP
        }
      >
        {data.state === 'assigned' && updateButton}
        {!['arrived', 'started', 'successful', 'cancelled'].includes(data.state) && assignButton}
        {['collected', 'arrived', 'started'].includes(data.state) && transferButton}
        {allocateButton}
        {unallocateButton}
        {data.state !== 'successful' && completeButton}
        {editButton}
        {taskLabelButton}
        {printButton}
        {!['cancelled', 'successful', 'collected'].includes(data.state) && cancelButton}
        {data.state !== 'unassigned' && restartButton}
        {duplicateButton}
        {archiveButton}
      </Component.LineItem.ButtonMenu>
    );
  }

  render() {
    const {
      archived, checkedStatus, checkItem, data, renderActionButtons,
      renderPersistentActionButtons, onClick, showTaskStates, showCheckBox,
    } = this.props;

    return (
      <Component.LineItem
        checkItem={checkItem}
        checkedStatus={checkedStatus}
        id="vf-task__list-item"
        itemID={parseInt(data.id, 10)}
        onClick={() => onClick(data)}
        showCheckBox={showCheckBox}
      >

        {this._renderCol1()}
        {this._renderCol2()}
        <Component.LineItem.Divider />
        {this._renderCol3()}
        <Component.LineItem.Divider />
        {this._renderCol4()}
        <Component.LineItem.Divider />
        {this._renderCol5()}

        <Component.LineItem.Divider />
        {showTaskStates && !archived ? this._renderCol6() : false}
        {/* {
          !this.props.archived ?
            <Component.LineItem.Divider
              color={separatorColors[this.props.data.state]}
            /> : false
        } */}
        {showTaskStates && !archived ? this._renderCol7() : false}
        {(!Helper.isNullOrUndefined(renderActionButtons))
          ? renderActionButtons(data)
          : this._renderActionButtons()}
        {(!Helper.isNullOrUndefined(renderPersistentActionButtons))
          ? renderPersistentActionButtons(data)
          : this._renderPersistentActionButtons()}

      </Component.LineItem>
    );
  }
}

TaskListItem.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  account: PropTypes.shape({
    id: PropTypes.number,
    addons_setting: PropTypes.shape(),
    direct_child_accounts: PropTypes.arrayOf(PropTypes.shape()),
    features_setting: PropTypes.shape({
      virtualized_list: PropTypes.number,
    }),
    parent_accounts: PropTypes.arrayOf(PropTypes.shape()),
    runsheet_manager_enabled: PropTypes.bool,
  }),
  archived: PropTypes.bool,
  checkItem: PropTypes.func,
  checkedStatus: PropTypes.bool,
  customTaskLayout: PropTypes.shape({
    col_2: PropTypes.arrayOf(PropTypes.string),
    col_3: PropTypes.arrayOf(PropTypes.string),
    col_4: PropTypes.arrayOf(PropTypes.string),
    col_5: PropTypes.arrayOf(PropTypes.string),
  }),
  data: PropTypes.shape({
    account_id: PropTypes.number,
    account_created_id: PropTypes.number,
    allocated_account_ids: PropTypes.arrayOf(PropTypes.number),
    allocation_state: PropTypes.string,
    archived: PropTypes.bool,
    epod_url: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    is_partial_success: PropTypes.bool,
    job: PropTypes.shape(),
    line_item_discrepancy: PropTypes.number,
    remarks: PropTypes.string,
    state: PropTypes.string,
    task_assignment: PropTypes.shape(),
  }),
  dispatch: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isActionButtonLoading: PropTypes.bool,
  isUpdatingVersadrive: PropTypes.bool,
  onClick: PropTypes.func,
  onSuccessfulUpdate: PropTypes.func,
  renderActionButtons: PropTypes.func,
  renderPersistentActionButtons: PropTypes.func,
  showCheckBox: PropTypes.bool,
  showTaskStates: PropTypes.bool,
  strings: PropTypes.shape({
    Common: PropTypes.shape({
      models: PropTypes.shape({
        task: PropTypes.shape({
          singular: PropTypes.string,
        }),
      }),
    }),
    Task: PropTypes.shape(),
  }).isRequired,
  tags: PropTypes.shape({}),
  taskFilter: PropTypes.shape({}),
};

TaskListItem.defaultProps = {
  account: null,
  archived: false,
  checkItem: null,
  checkedStatus: false,
  customTaskLayout: {
    col_2: ['account_name', 'task_contact_person', 'task_id', 'tracking_id'],
    col_3: ['task_address', 'task_time_range'],
    col_4: [],
    col_5: ['task_tags', 'item_summary'],
  },
  data: null,
  isActionButtonLoading: false,
  isUpdatingVersadrive: false,
  onClick: task => Router.transitionFromSubpath('tasks', task.id),
  onSuccessfulUpdate: () => {},
  renderActionButtons: null,
  renderPersistentActionButtons: null,
  showCheckBox: true,
  showTaskStates: true,
  taskFilter: null,
  tags: {},
};

TaskListItem.displayName = 'Task List Item';

function mapStateToProps(state) {
  const { profile } = state;
  return {
    accessRights: profile.profile.access_rights,
  };
}

export default connect(mapStateToProps)(translate('')(TaskListItem));
